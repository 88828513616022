.react-date-picker__button__icon {
  stroke: white !important;
}

.react-date-picker__inputGroup:hover {
  border: 1px solid #a9a9a9 !important;
  border-radius: 4px !important;
  color: white !important;
}

.react-date-picker__inputGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.react-date-picker__inputGroup:hover {
  border: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white !important;
  box-shadow: none !important;
  outline: none !important;
}

.react-date-picker__inputGroup input {
  border: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white !important;
  box-shadow: none !important;
  outline: none !important;
}

.react-date-picker__inputGroup__input {
  background-color: transparent !important;
}

.react-date-picker__inputGroup__input:hover {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
}

.react-date-picker__button__icon:hover {
  stroke: #a9a9a9 !important;
}

.white-placeholder::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white;
}
.white-placeholder::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
}
.white-placeholder:-ms-input-placeholder {
  /* IE 10+ */
  color: white;
}
.white-placeholder:-moz-placeholder {
  /* Firefox 18- */
  color: white;
}
