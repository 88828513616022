.app {
  width: 100%;
  background-color: #37384755;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100vw;
  height: 100vh;
  background: url("https://wydglobal.raidhut.com/templates/ja_healthcare/images/signup/bg.jpg");
}
